import { mapActions } from 'vuex';
export default{
    name:'TwoFaMixins',
    methods:{
        ...mapActions(['set2Fastatus']),
        getSecret(){
            return new Promise((resolve,reject)=>{
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/users/2fa/get-secret?user_id=${this.$store.state.app.user.user_id}`,
                };
                this.$http(options)
                    .then(res => { resolve(res) })
                    .catch(err => { reject(err) })
            })
        },
        verifyOtp(otp){
            return new Promise((resolve,reject)=>{
                const bodyFormData = new FormData()
                bodyFormData.append('one_time_password', otp)
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/users/2fa/enable-2fa`,   
                    data:bodyFormData,
                };
                this.$http(options)
                    .then(res => {
                        let twofa_enabled = res.data.data.user.is_2fa_enabled;
                        this.$store.commit('app/set2Fastatus',twofa_enabled)
                        
                        resolve(res) })
                    .catch(err => { reject(err) })
            })
        },
        disable2FA(otp){
            return new Promise((resolve,reject)=>{
                const bodyFormData = new FormData()
                bodyFormData.append('one_time_password', otp)
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/users/2fa/disable-2fa`, 
                    data:bodyFormData,  
                };
                this.$http(options)
                    .then(res => {
                        let twofa_enabled = res.data.data.user.is_2fa_enabled;
                        this.$store.commit('app/set2Fastatus',twofa_enabled)
                        resolve(res) })
                    .catch(err => { reject(err) })
            })
        }
    }
}