<template>
  <div class="card">
    <div class="card-body" >
      Enable Two Factor authentication

      <b-form-checkbox
        checked="false"
        class="custom-control-primary mt-1"
        name="check-button"
        switch
        v-model="enable2fa"
        @change="getSecret2Fa"
        v-if="!otpenter"
      >
        <span class="switch-icon-left">
          <feather-icon icon="LockIcon" />
        </span>
        <span class="switch-icon-right">
          <feather-icon icon="UnlockIcon" />
        </span>
      </b-form-checkbox>
      <div v-if="this.$store.state.app.twofastatus != 1 && !otpenter" class="mt-1">
        <b>What is 2FA?</b> <br />

        2FA is an extra layer of security used to make sure that people trying
        to gain access to an online account are who they say they are. First, a
        user will enter their username and a password. Then, instead of
        immediately gaining access, they will be required to provide another
        piece of information. Secusy uses a uses a software-generated
        time-based, one-time passcode (also called TOTP, or “soft-token”).
        First, a user must download and install a free 2FA app on their
        smartphone or desktop. They can then use the app with any site that
        supports this type of authentication. At sign-in, the user first enters
        a username and password, and then, when prompted, they enter the code
        shown on the app. <br />
        <b>Add your device </b> <br />
        Install a 2FA app such as Google Authenticator or Microsoft
        Authenticator or any authent app on your device. Add CISO app by add the below Code. You
        are all set to use 2FA on your account.
      </div>

      <div class="google_authenticator" v-if="this.$store.state.app.twofastatus != 1 && !otpenter">
        <div class="text-center mt-1">
          <h3>Set up Authenticator</h3>
          <p>Set up your two factor authentication by use the code below</p>
          <div class="auth-code-div">
            <div class="auth-code">
              <h3 v-if="secret2fa == ''"><b>Enable switch and Please Wait ..</b></h3>
              <h3 v-else>
                <b>{{ secret2fa }}</b>
              </h3>
            </div>
          </div>
          <p class="mt-2">
            You must set up your Authenticator app before continuing. You
            will be unable to login otherwise
          </p>
          <button class="btn btn-primary mt-1" @click="continue2fa()" :disabled="secret2fa == ''">
            Continue
          </button>
        </div>
      </div>
      <div class="google-auth-otp mt-1" v-if="otpenter">
        <p>
          Please enter the <span><b>OTP</b></span> generated on your
          Authenticator app.
        </p>
        <label for="">One Time Password</label>
        <input
          type="text"
          class="form-control"
          style="max-width: 250px"
          v-model="onetimepwd"
        />
        <button class="btn btn-primary mt-1" @click="submitOtp">Submit</button>
      </div>
    </div>
    <b-modal
      id="modal-disable-2fa"
      title="Are you sure to Disable 2FA?"
      ok-only
      ok-title="Disable 2FA"
      ref="modal-disable-2fa"
      @ok="disable2faenable"
      size="lg"
    >
      <b-card-text>
        <p>Your account is more secure when you need a password and a verification code to sign in. If you remove this extra layer of security, you will only be asked for a password when you sign in. It might be easier for someone to break into your account.</p>
        <h5 class="mt-1">For Disable 2FA Enter OTP from your Authenticator App</h5>
        <input type="text" class="form-control" style="max-width:200px;" v-model="disableotp">
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import { BFormCheckbox } from "bootstrap-vue";
import ResponseMixins from "../../mixins/ResponseMixins";
import TwoFaMixins from "../../mixins/TwoFaMixins";
export default {
  data() {
    return {
      enable2fa: false,
      otpenter: false,
      secret2fa: "",
      onetimepwd: "",
      disableotp:''
    };
  },
  components: {
    BFormCheckbox,
  },
  mixins: [ResponseMixins, TwoFaMixins],
  mounted() {
    this.get2FAstatus();
  },
  methods: {
    continue2fa() {
      this.otpenter = true;
    },
    get2FAstatus() {
      this.enable2fa = this.$store.state.app.twofastatus == 1 ? true : false;
    },
    getSecret2Fa() {
      if (this.enable2fa) {
        this.getSecret()
          .then((res) => {
            this.secret2fa = res.data.data.secret;
          })
          .catch((err) => {});
      }
      if (!this.enable2fa) {
        this.$refs['modal-disable-2fa'].show();
      }
        
    },
    disable2faenable(){
        this.disable2FA(this.disableotp)
          .then((res) => {
            this.handleResponse(res);
          })
          .catch((err) => {
            this.handleError(err);
          });
      
    },
    submitOtp() {
      this.verifyOtp(this.onetimepwd)
        .then((res) => {
            this.handleResponse(res);
            this.otpenter = false;
        })
        .catch((err) => {
            this.handleError(err);
        });
    },
  },
};
</script>
<style scoped>
.auth-code {
  display: flex;
  background: #ccc;
  color: #000;
  align-items: center;
  justify-content: center;
  max-width: auto;
  padding: 0.5rem 1rem;
}
.auth-code-div {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>